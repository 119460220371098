@import "fonts";
@import "libs";
@import "vars";

* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	&:before, &:after {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box; } }

*::-webkit-input-placeholder {
	color: #bdbdbd;
	opacity: 1; }

*:-moz-placeholder {
	color: #bdbdbd;
	opacity: 1; }

*::-moz-placeholder {
	color: #bdbdbd;
	opacity: 1; }

*:-ms-input-placeholder {
	color: #bdbdbd;
	opacity: 1; }

body input:focus:required:invalid,
body textarea:focus:required:invalid {
	color: #bdbdbd; }
body input:required:valid,
body textarea:required:valid {
	color: #bdbdbd; }

html, body {
	height: 100%;
	background-color: #fff;
	margin: 0;
	padding: 0; }

body {
	min-width: 320px;
	font-weight: 300;
	position: relative;
	line-height: 1.5;
	font-family: $text-font;
	font-size: 14px;
	color: $text-color;
	overflow-x: hidden;
	opacity: 1; }
button,
a {
	outline: none;
	border-radius: 0;
	cursor: pointer;
	transition: .2s all; }

input,
textarea {}
fieldset {
	border: none;
	padding: 0;
	margin: 0; }
input,
textarea {
	outline: none;
	background: none;
	border: 1px solid lighten($text-color, 40%);
	border-radius: 5px;
	padding: 7px;
	font-size: 13px;
	&:focus {
		outline: none; } }

a,.btn {
	transition: all .2s linear; }

a {
	color: $accent-color;
	text-decoration: none;
	&:hover {
		color: $text-color; } }
h1, h2, h3 {
	font-weight: 400;
	font-family: $header-font;
	text-transform: uppercase;
	margin: 0;
	line-height: 1.2;
	letter-spacing: .2px; }
h1 {
	margin-bottom: 15px;
	font-size: 30px; }
h2 {
	margin-bottom: 7px;
	font-size: 24px; }
h3 {
	margin-bottom: 7px;
	font-size: 20px; }
img {
    max-width: 100%; }
.btn {
	text-decoration: none;
	background: none;
	border: none;
	outline: none;
	display: inline-block;
	border-radius: 0;
	cursor: pointer;
	line-height: 1;
	padding: 5px 12px;
	text-transform: uppercase;
	font-family: $header-font;
	font-size: 16px;
	border: 1px solid $text-color;
	color: $text-color;
	&-primary {
		color: $accent-color;
		border-color: $accent-color;
		font-weight: 600;
		&:hover {
			color: $text-color;
			border-color: $text-color; } } }

ul {
	&.list-unstyled,
	&.list-inline {
		list-style: none;
		margin: 0;
		padding: 0; }
	&.list-inline {
		li {
			display: inline-block;
			& + li {
				margin-left: 7px; } } } }

.text-left {
	text-align: left; }
.text-right {
	text-align: right; }

.error-message, .text-danger {
	color: $red-color;
	font-size: 13px;
	margin: 5px 0; }
.alert {
	margin-bottom: 20px;
	padding: 15px 35px 15px 15px;
	position: relative;
	background-color: #fff;
	button {
		background: none;
		border: none;
		position: absolute;
		right: 5px;
		top: 5px; }
	&-danger {
		border: 1px solid $red-color; }
	&-success {
		border: 1px solid $accent-color; } }


.header {
	background-color: #000;
	padding: 10px 0;
	border-bottom: 1px solid #fff;
	color: #fff;
	>.container {
		display: flex;
		justify-content: space-between;
		align-items: center; }
	a {
		color: #fff; }
	&-logo {
		width: 150px;
		img {
			max-width: 100%; } }
	&-phone, &-regim {
		display: flex;
		align-items: center;
		font-size: 15px;
		.la {
			margin-right: 10px;
			font-size: 22px;
			color: $accent-color; } }
	&-callback {
		margin-top: 5px;
		text-align: center;
		a {
			display: inline-block;
			&:hover {
				color: $accent-color; }
			> * {
				display: inline-block;
				vertical-align: middle; }
			.la {
				font-size: 16px;
				color: $accent-color; } } }
	&-cart {
		> #cart > a {
			display: block;
			border: 1px solid $accent-color;
			border-radius: 5px;
			text-align: center;
			padding: 1px 10px 2px 10px;
			font-size: 13px;
			* {
				vertical-align: middle;
				display: inline-block;
				line-height: 1; }
			.cart-total span:last-of-type {
				margin-left: 5px; }
			.la {
				font-size: 25px;
				line-height: 1;
				color: $accent-color; }
			&:hover {
				border-color: #fff;
				.la {
					color: #fff; } } } }
	&-profile {
		margin-top: 5px;
		a {
			display: inline-block;
			&:hover {
				color: $accent-color; }
			& + a {
				margin-left: 10px; }
			.la {
				font-size: 16px;
				color: $accent-color; } } } }
.header-search-form {
	background-color: #fff;
	border-radius: 5px;
	display: inline-block;
	input {
		background: none;
		border: none;
		font-size: 13px;
		padding: 5px 5px 5px 10px;
		border-radius: 5px; }
	button {
		background: none;
		border: none;
		padding-right: 10px;
		.la {
			font-size: 16px;
			color: $text-color; } } }
.main-menu {
	background-color: #000;
	padding-top: 10px;
	.button-search {
		display: none;
		button.toggle-menu {
			border: none;
			background: none;
			color: #fff;
			padding: 0;
			&:before {
				content: '';
				font-family: lineawesome;
				font-size: 30px;
				line-height: 1; } } }

	ul {
		display: flex;
		justify-content: space-between;
		li {
			position: relative;
			z-index: 2;
			> a {
				color: #ffffff;
				text-transform: uppercase;
				font-family: $header-font;
				text-decoration: none;
				font-size: 18px;
				display: inline-block;
				padding-bottom: 8px;
				border-bottom: 2px solid transparent;
				&:hover, &.active {
					border-bottom: 2px solid #fff; } }
			ul {
				display: none;
				position: absolute;
				background-color: #000;
				width: 320px;
				a {
					color: #ffffff;
					text-transform: uppercase;
					font-family: $header-font;
					text-decoration: none;
					font-size: 15px;
					padding: 10px;
					display: block; } }
			&:hover {
				ul {
					display: block; } } } } }

.category-nav {
	margin: 30px 0 40px 0;
	ul {
		display: flex;
		justify-content: space-around;
		li {
			width: 250px;
			a {
				display: block;
				border: 1px #000 solid;
				padding: 5px;
				span {
					display: block; }
				.image {
					background-repeat: no-repeat;
					background-position: center center;
					background-size: contain;
					height: 175px;
					margin-bottom: 10px; }
				.name {
					text-transform: uppercase;
					font-family: $header-font;
					font-size: 22px;
					text-align: center; } } } } }

.home-category-wall .module-product-block {
	margin-bottom: 40px;
	h2 {
		text-align: center;
		font-weight: 400;
		font-size: 24px;
		line-height: 1;
		background-color: lighten($accent-color, 15%);
		padding: 7px 0;
		margin-bottom: 25px;
		a {
			color: #fff;
			display: block;
			&:hover {
				color: $text-color; } } }
	&:last-of-type {
		margin-bottom: 0; } }

.owl-carousel {
	background: none;
	.owl-wrapper-outer {
		background: none;
		border: none;
		border-radius: 0;
		box-shadow: none;
		text-align: center; }
	.owl-item {
		text-align: center;
		.product-card {
			display: inline-block; } }
	.owl-nav, .owl-prev, .owl-next {
		position: absolute; }
	.owl-nav {
		width: 100%;
		left: 0;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		.owl-prev, .owl-next {
			width: 30px;
			height: 30px;
			display: inline-block;
			border: $accent-color 1px solid;
			transform: rotate(-45deg);
			&.disabled {
				opacity: .3;
				cursor: default; } }
		.owl-prev {
			left: 15px;
			border-right: none;
			border-bottom: none; }
		.owl-next {
			right: 15px;
			border-left: none;
			border-top: none; } }
	.owl-dots {
		position: absolute;
		z-index: 1;
		bottom: 5px;
		left: 50%;
		transform: translateX(-50%);
		.owl-dot {
			background-color: $white-color;
			width: 15px;
			height: 15px;
			border-radius: 7.5px;
			display: inline-block;
			margin: 0 3px;
			opacity: .5;
			border: $accent-color 1px solid;
			&.active, &:hover {
				opacity: 1; } } } }

.slideshow {
	margin-bottom: 25px; }

.product-card {
	width: 250px;
	text-align: center;
	position: relative;
	margin-bottom: 15px;
	.image {
		border: 1px solid lighten($accent-color, 20%);
		margin-bottom: 10px;
		img {
			max-width: 100%; } }
	h3.name {
		// font-weight: 600
		margin: 0;
		font-size: 20px;
		margin-bottom: 10px;
		height: 93px;
		text-align: left;
		overflow: hidden; }
	.description {
		display: none; }
	.stock {
		margin-bottom: 5px; }
	.price {
		font-size: 20px;
		&-old {
			font-size: 15px;
			color: lighten($text-color, 30%);
			position: relative;
			z-index: 1;
			&:after {
				content: '';
				height: 1px;
				background-color: $red-color;
				position: absolute;
				top: 50%;
				left: 0;
				right: 0;
				z-index: 1;
				transform: translateY(-50%); } }
		&-new {
			color: $red-color; } }
	.buttons {
		&-cart {
			margin: 10px 0;
			border: 1px solid #000;
			display: flex;
			justify-content: space-around;
			padding: 2px 5px;
			a {
				text-transform: uppercase;
				color: #000;
				font-family: $header-font;
				font-size: 16px;
				&:hover {
					color: $accent-color; } } }
		&-compare {
			a {
				font-weight: 600; } } } }

.product-card-list {
	.product-card-block {
		width: 100%;
		max-width: 100%;
		display: block;
		flex: 0 0 100%; }
	.product-card {
		width: 100%;
		display: flex;
		justify-content: space-between;
		.image {
			width: 20%;
			padding: 2px;
			img {
				max-width: 100%; } }
		.name-description-stock {
			width: 50%;
			.name {
				height: auto; } }
		.price-buttons {
			width: 27%; }
		.description {
			margin-bottom: 5px;
			font-size: 13px;
			display: block;
			text-align: left; }
		.stock {
			color: $accent-color;
			text-align: left; }
		.price {
			margin-bottom: 7px;
			> span {
				display: block;
				text-align: center; } } } }
.pagination-results {
	margin-top: 15px;
	border-top: 1px solid #000;
	padding-top: 15px;
	display: flex;
	justify-content: space-between;
	.pagination {
		margin: 0;
		padding: 0;
		list-style-type: none;
		li {
			display: inline-block;
			+ li {
				margin-left: 7px; }
			a, span {
				font-size: 16px; } } } }


.nowrap {
	white-space: nowrap; }

.compare-widget {
	a {
		position: fixed;
		text-decoration: none;
		right: 0;
		top: 25%;
		background-color: #fff;
		opacity: .6;
		box-shadow: 0 1px 3px #cecece;
		border: 1px $accent-color solid;
		border-right: none;
		padding: 5px 7px;
		z-index: 1;
		.la, span {
			display: block;
			text-align: center; }
		.la {
			margin-bottom: 7px;
			font-size: 20px; }
		&:hover {
			opacity: 1; } } }

.breadcrumb {
	ul {
		margin: 0;
		padding: 0;
		list-style: none;
		margin-bottom: 15px;
		margin-top: 10px;
		li {
			display: inline-block;
			font-size: 13px;
			& + li {
				margin-left: 4px;
				&:before {
					content: '/';
					margin-right: 4px; } }
			a {
				font-size: 13px; } } } }
#column-left {
	> div {
		margin-bottom: 25px; } }

.sidebar-category-list {
	li {
		border-top: 1px solid $text-color;
		padding: 10px 0;
		&:last-of-type {
			border-bottom: 1px solid $text-color; }
		a {
			font-size: 16px;
			text-transform: uppercase;
			display: block;
			color: $text-color;
			position: relative;
			&:after {
					content: '';
					position: absolute;
					font-size: 12px;
					right: 0;
					top: 50%;
					transform: translateY(-50%);
					font-family: LineAwesome; }
			&:hover, &.active {
				color: $accent-color; } } } }

.panel-manage-products {
	margin-bottom: 20px;
	padding: 5px 10px;
	background-color: lighten($accent-color, 15%);
	> .row {
		align-items: center; }
	.display-button {
		white-space: nowrap;
		button {
			display: inline-block;
			vertical-align: middle;
			background: none;
			border: none;
			padding: 0;
			.la {
				font-size: 30px;
				line-height: 1;
				color: #fff; }
			&.active {
				.la {
					color: $text-color; } } } }
	.sort-limit {
		white-space: nowrap;
		text-align: center;
		line-height: 1;
		label {
			font-family: $header-font;
			font-size: 16px; }
		> * {
			display: inline-block;
			vertical-align: bottom;
			select {
				max-width: 150px; } }
		.limit {
			margin-left: 10px; } }
	.compare {
		text-align: right;
		a {
			font-family: $header-font;
			font-size: 16px;
			color: $text-color;
			text-transform: uppercase;
			display: inline-block;
			vertical-align: middle;
			line-height: 1;
			border-bottom: 1px solid;
			&:hover {
				color: #fff; } } } }

.product-info {
	.main-image {
		margin-bottom: 15px;
		border: 1px lighten($accent-color, 20%) solid;
		img {
			max-width: 100%; } }
	.image-additional-item {
		a {
			display: block;
			text-align: center;
			border: 1px solid transparent;
			padding: 5px;
			&.active {
				border: 1px solid $accent-color; } } }
	.product-info-mobile-images {
		.image-additional-item {
			border: 1px lighten($accent-color, 20%) solid; } }
	.find-cheaper {
		margin-bottom: 10px;
		a {
			text-decoration: none; } }
	.right-panel {
		.price {
			margin-bottom: 10px;
			font-size: 30px;
			color: $accent-color;
			&-old {
				font-size: 18px;
				color: $text-color;
				position: relative;
				&:after {
					content: '';
					height: 1px;
					background-color: $red-color;
					position: absolute;
					top: 50%;
					left: 0;
					right: 0;
					transform: translateY(-50%); } }
			&-new {
				color: $red-color; } } }
	.product-action-one {
		margin-bottom: 15px;
		> div,
		.qty-input > * {
			display: inline-block;
			vertical-align: middle; }
		input {
			width: 50px;
			text-align: center; }
		button.plus, button.minus {
			border: none;
			background: none;
			.la {
				font-size: 20px; } }
		.button-cart {
			margin-left: 10px; } }
	.product-action-two {
		margin-bottom: 15px;
		border: 1px $text-color solid;
		padding: 5px;
		display: flex;
		justify-content: space-around;
		.sep {
			border-left: 1px $text-color solid;
			margin-left: 5px;
			padding-left: 5px; }
		button {
			border: none;
			background: none;
			padding: 0; }
		a,button {
			text-transform: uppercase;
			font-size: 16px;
			font-family: $header-font;
			color: $text-color;
			text-align: center;
			&:hover {
				color: $accent-color; } } } }
.product-attribute {
	margin-bottom: 7px;
	table {
		width: 100%;
		tr {
			td {
				width: 49%;
				padding: 2.5px 0;
				&:last-of-type {
					text-align: center;
					font-size: 13px; } } } } }
.product-description {
	border-top: 1px $text-color solid;
	margin-top: 18px;
	padding-top: 15px;
	&-content {
		h1, h2, h3 {
			color: $accent-color; } } }
iframe {
	width: 100%!important; }

.product-download {
	margin-bottom: 15px; }

.flycart {
	&-heading {
		h3 {
			margin-bottom: 15px; }
		p {
			margin: 0;
			margin-bottom: 15px; }
		a {
			border-bottom: 1px solid; } }

	.products-item {
		border-top: 1px $text-color solid;
		padding-top: 10px;
		margin-bottom: 10px;
		.name {
			margin-bottom: 2px;
			text-align: left;
			a {
				text-transform: uppercase;
				font-size: 16px;
				font-family: $header-font; } }
		.stock,
		.quantity {
			color: lighten($text-color, 50%); }
		.stock {
			text-align: left; }
		.total {
			white-space: nowrap; } }
	&-buttons {
		border-top: 1px $text-color solid;
		padding-top: 15px;
		display: flex;
		justify-content: space-between; } }

.form-group {
	&.required {
		label {
			&:after {
				content: '*';
				color: #ff0000;
				margin-left: 3px; } } }
	label {
		display: block;
		margin-bottom: 3px; }
	input[type="text"],
	input[type="tel"],
	input[type="email"],
	input[type="password"],
	textarea {
		display: block;
		width: 100%; }
	& + &,
	& + .buttons, {
		margin-top: 10px; } }

div.table-responsive {
	overflow-x: auto; }
.table {
	width: 100%;
	margin-bottom: 20px;
	&.table-bordered {
		td {
			padding: 5px;
			background-color: #fff; }
		tr:nth-child(even) {
			td {
				background-color: lighten($text-color, 90%); } }
		thead {
			td {
				font-weight: 500; } } } }

.mfp-ajax-holder .mfp-content {
	max-width: 600px;
	width: 100%;
	background-color: #fff; }
.wrapper-popup-form {
	> div {
		padding: 15px;
		text-align: center; }
	h2 {
		margin-bottom: 15px; }
	.buttons {
		margin-top: 15px; } }

.simplecheckout-cart {
	margin-bottom: 20px;
	&-products {
		.item {
			display: flex;
			align-items: center;
			margin-bottom: 15px;
			border-top: 1px lighten($text-color, 50%) solid;
			padding-top: 15px;
			> * {
				padding: 5px; }
			.product {
				width: 50%;
				.image {
					float: left;
					border: 1px solid lighten($accent-color, 20%); }
				.meta {
					margin-left: 120px;
					.name {
						margin-bottom: 5px;
						a {
							font-family: $header-font;
							font-size: 17px; } }
					.model {
						margin-bottom: 10px;
						font-size: 12px; }
					.price {
						color: $accent-color;
						font-size: 18px;
						font-family: $header-font;
						span {
							color: lighten($text-color, 50%);
							font-size: 14px; } } } }
			.quantity,
			.total,
			.remove {
				text-align: center; }
			.quantity {
				width: 30%;
				white-space: nowrap;
				> * {
					display: inline-block;
					vertical-align: middle; }
				button {
					background: none;
					border: none;
					.la {
						font-size: 20px; } }
				input {
					width: 40px;
					text-align: center; } }
			.total {
				width: 15%;
				font-family: $header-font;
				font-size: 20px;
				white-space: nowrap;
				span {
					color: lighten($text-color, 50%);
					font-size: 15px; } }
			.remove {
				width: 5%;
				button {
					background: none;
					border: none;
					.la {
						font-size: 18px;
						color: $red-color; } } } } }
	&-modules {
		border-top: 1px lighten($text-color, 30%) solid;
		padding-top: 25px;
		display: table;
		width: 100%;
		>* {
			display: table-cell;
			padding: 5px;
			vertical-align: middle;
			&:first-child {
				width: 85.5%;
				text-align: right; } }
		.form-control {
			width: auto; } }
	&-totals {
		display: table;
		width: 100%;
		font-family: $header-font;
		font-size: 17px;
		> div {
			display: table-row;
			> span {
				display: table-cell;
				padding: 5px;
				&:first-child {
					text-align: right;
					width: 85.5%; }
				&:last-of-type {
					white-space: nowrap;
					font-size: 17px; } } }
		#total_total {
			font-size: 16px;
			span {
				&:last-of-type {
					color: $accent-color;
					font-size: 19px;
					font-weight: 600; } } } } }
.simplecheckout-block {
	h2 {
		border-top: 1px solid lighten($text-color, 50%);
		border-bottom: 1px solid lighten($text-color, 50%);
		padding: 7px 0; }
	.radio {
		margin-bottom: 12px;
		input {
			margin-right: 5px; } } }

#simplecheckout_shipping_address,
#simplecheckout_comment {
	margin-top: 15px; }
#agreement_checkbox {
	margin-bottom: 10px; }

#simplecheckout_shipping {
	b {
		font-family: $header-font;
		font-weight: 600; }
	p {
		margin-bottom: 3px; } }



.scroll-to-top {
	position: fixed;
	background: none;
	background-color: $text-color;
	border: none;
	bottom: 10px;
	left: 15px;
	display: none;
	z-index: 1;
	font-size: 35px;
	color: #fff; }

.search-parameters {
	margin-bottom: 20px; }

#main-content-footer {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between; }


.footer {
	width: 100%;
	margin-top: 15px;
	padding: 15px 0;
	background-color: #000;
	color: #fff;
	font-size: 13px;
	h4 {
		font-family: $header-font;
		font-weight: 400;
		font-size: 20px;
		margin: 0;
		margin-bottom: 10px; }
	.contact-info {
		.la {
			color: $accent-color; } }
	a:hover {
		text-decoration: underline;
		color: $accent-color; }
	&-bottom {
		border-top: 1px #fff solid;
		padding-top: 15px;
		> .container {
			display: flex;
			justify-content: space-between;
			align-items: center; } }
	.powered {
		font-family: $header-font;
		font-size: 16px;
		color: #fafafa; } }

@import "media"; // Всегда в конце
