@import "vars";

@media only screen and (max-width: 1200px) {
  #column-left {
    .product-card {
      width: 100%;
      .buttons-cart {
        display: block;
        a {
          display: block;
          &:last-of-type {
            border-top: 1px solid #000; } }
        .sep {
          display: none; } } } }
  .list-product-page .product-card-block {
    text-align: center;
    .product-card {
      display: inline-block; } } }
@media only screen and (max-width: 992px) {
  .header {
    &-logo {
      width: 115px; }
    &-phone, &-regim {
      font-size: 12px;
      display: block;
      text-align: center;
      .la {
        margin-right: 4px;
        font-size: 13px; } }
    &-search-callback {
      width: 170px;
      input {
        width: 135px; } } }
  .category-nav ul li {
    width: 200px;
    a .name {
      font-size: 20px; } }
  .product-card {
    width: 200px; }
  .owl-carousel {
    .owl-nav {
      .owl-prev, .owl-next {
        width: 20px;
        height: 20px; }
      .owl-prev {
        left: 0; }
      .owl-next {
        right: 0; } } }
  .panel-manage-products {
    .compare {
      display: none; } }
  .product-info {
    .right-panel {
      .price-new {
        display: block; } }
    .product-action-one {
      input {
        width: 45px; } } }
  .simplecheckout-cart-products {
    .item {
      position: relative;
      .remove {
        position: absolute;
        top: 5px;
        right: 0; } } } }

@media only screen and (max-width: 767px) {
  .header {
    padding: 5px 0;
    > .container {
      flex-wrap: wrap; }
    &-logo {
      width: 115px; }
    &-cart-profile {
      width: 58%;
      order: 2; }
    &-phone {
      order: 3; }
    &-regim {
      order: 4; }
    &-search-callback {
      width: 100%;
      order: 5; }
    &-search {
      display: none; }
    &-phone, &-regim {
      font-size: 13px;
      margin-top: 5px;
      width: 50%;
      display: flex;
      .la {
        margin-right: 7px;
        font-size: 18px; } }
    &-callback {
      font-size: 13px; }
    &-cart {
      #cart > a {
        padding-top: 0;
        padding-bottom: 1px;
        .cart-total span:last-of-type {
          display: none; }
        .la {
          font-size: 20px; } } }
    &-profile {
      a {
        font-size: 13px; } } }
  .main-menu {
    margin-bottom: 15px;
    padding-bottom: 5px;
    padding-top: 5px;
    .button-search {
      display: flex;
      justify-content: space-between;
      align-items: center; }
    .transform i {
      transform: rotate(180deg); }
    ul {
      display: none;
      li {
        margin-top: 5px;
        > a {
          font-size: 17px;
          padding-bottom: 0;
          border-bottom: 1px solid transparent;
          &:hover {
            border-bottom: 1px solid transparent; }
          &.active {
            border-bottom: 1px solid #fff; } }
        ul {
          position: static;
          li {
            margin-top: 3px; }
          a {
            padding: 0px;
            padding-left: 10px; } }
        &:hover {
          ul {
            display: none; } } } } }
  .home-category-wall {
    .module-product-block {
      margin-bottom: 10px;
      h2 {
        margin-bottom: 10px; } } }
  .product-card {
    width: 200px;
    h3.name {
      height: auto; } }
  .panel-manage-products {
    .display-button,
    .sort-limit .limit,
    .compare {
      display: none; } }


  .pagination-results {
    text-align: center;
    display: block; }
  .scroll-to-top {
    left: 5px; }
  .footer {
    h4 {
      margin: 0;
      padding: 0;
      line-height: 1;
      margin-bottom: 3px; }
    p {
      margin: 0;
      padding: 0;
      margin-bottom: 4px; }
    &-top {
      > .container > .row > div {
        margin-bottom: 10px; } }
    &-bottom {
      padding-top: 10px;
      > .container {
        display: block; }
      .powered {
        margin-bottom: 5px;
        text-align: center; }
      .icons {
        text-align: center; } } }
  .slideshow-wrap {
    display: none; }
  .product-info {
    .product-action-two,
    .product-attribute {
      margin-bottom: 5px;
      .table {
        margin-bottom: 0; } } }
  .product-description {
    margin-top: 5px;
    padding-top: 10px; }
  .simplecheckout-cart-products {
    .item {
      display: block;
      .product {
        width: 100%;
        display: inline-block;
        vertical-align: middle; }
      .quantity,
      .total {
        width: auto;
        margin-left: 110px;
        text-align: left;
        display: inline-block;
        vertical-align: middle;
        span {
          display: inline; } }
      .remove {
        display: inline-block;
        vertical-align: middle; } } }
  .simplecheckout-cart-modules {
    .simplecheckout-cart-total {
      input {
        width: 115px; } } } }
// @media only screen and (max-width: 575px)



